// WelcomeMessage.js
import React from 'react';
import './WelcomeMessage.css'; // Import CSS file for styling

function WelcomeMessage() {
  return (
    <div className="welcome-message">
      <h1>Welcome To the Forbidden NFT Minting website</h1>
      <p>Mint them if you dare!!!!</p>
    </div>
  );
}

export default WelcomeMessage;

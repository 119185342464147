import React from 'react';
import './App.css';
import WelcomeMessage from './WelcomeMessage';
import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";

function App() {
  return (
    <div className="App">
      <WelcomeMessage />
      <Mint />
    </div>
  );
}

function Mint() {
  return (
    <CrossmintPayButton
      collectionId="a5ed20f0-f563-49b3-a0d7-255c6b175ce8"
      projectId="9cf4650c-5528-47aa-9c70-11a2917efff9"
      mintConfig={{ quantity: 1, type: "managed-erc-721" }}
      checkoutProps={{ paymentMethods: ["fiat", "ETH", "SOL"] }}
    />
  );
}

export default App;
